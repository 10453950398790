<template>
  <ul class="v-tree-container" :class="styleClass(nodes)">
    <li class="v-tree-item"
        v-for="(node,aindex) in nodes"
        v-show="node.open"
        @click.stop="currentChange(node)"
        >
      <p class="v-tree-content">
         <span class="v-tree-switch" v-if="node.children"
             :class="{'active':!node.children[0].open}"
             @click.stop="handleSwitch(node)"
         ></span>
        <span class="v-tree-line" v-else :class="{'v-tree-bottom':aindex==nodes.length-1}"></span>
        <a href="javascript:;" class="v-tree-title">
          <span class="v-tree-parent-icon" v-if="node.children"></span>
          <span class="v-tree-children-icon" v-else></span>
          <span class="node-title">{{node.label}}</span>
        </a>
        <span class="v-tree-btns">
          <slot :node="node"></slot>
        </span>
      </p>
      <tree-node
        v-if="node.children"
        :nodes="node.children"
        :currentChange="currentChange"
        :index="index+1"
      >
        <template slot-scope="{node}">
          <slot :node="node"></slot>
        </template>
      </tree-node>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'tree-node',
  props: {
    nodes: Array,
    index: {
      type: Number,
      default: 0
    },
    currentChange: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },
  methods: {
    handleSwitch (node) {
      this.changeStatus(node.children)
    },
    styleClass (nodes) {
        let hasLine = ''
        if(nodes.length==1){
            hasLine = 'no-line'
        }
        return ['level'+this.index,hasLine]
    },
    changeStatus (items) {
      let status = !items[0].open
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        item.open = status
      }
    }
  }
}
</script>

<style>
  .v-tree-container {
    padding-left: 18px;
    background: url(./images/line_conn.gif) 0 0 repeat-y;
  }

   .v-tree-container.no-line{
       background: none;
   }

  .v-tree-container.level0 {
    padding-left: 0;
    background: none
  }
  .v-tree-container .v-tree-item{
    clear: both;
    overflow: hidden;
  }
  .v-tree-container .v-tree-item .v-tree-content {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
  }
  .v-tree-container .v-tree-item .v-tree-switch,
  .v-tree-container .v-tree-item .v-tree-line,
  .v-tree-container .v-tree-item .v-tree-bottom{
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
    background: url(./images/zTreeStandard.png) no-repeat -92px 0;
    cursor: pointer;
  }

  .v-tree-container .v-tree-item .v-tree-switch.active{
    background-position: -74px -18px;
  }
  .v-tree-container .v-tree-item .v-tree-line{
    background-position: -56px -18px;
  }
   .v-tree-container .v-tree-item .v-tree-bottom{
      background-position: -56px -36px;
  }
  .v-tree-container .v-tree-item .v-tree-title{
    flex: 1;
    font-size: 14px;
    color: #333
  }
  .v-tree-container .v-tree-item .v-tree-parent-icon,
  .v-tree-container .v-tree-item .v-tree-children-icon{
    margin-right: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(./images/zTreeStandard.png) no-repeat -110px -16px;
    vertical-align: top;
  }
  .v-tree-container .v-tree-item .v-tree-children-icon{
    background-position: -110px -32px;
  }
  .v-tree-container .v-tree-content:hover{
    background: rgba(245,247,250,1);
  }
  .v-tree-container .v-tree-content .v-tree-btns{
    display: none;
    flex: 0 0 70px;
    width: 70px;
    text-align: center;
  }

  .v-tree-container .v-tree-content:hover .v-tree-btns{
    display: block;
  }

  .v-tree-container .v-tree-plus,
  .v-tree-container .v-tree-edit,
  .v-tree-container .v-tree-remove{
    display: inline-block;
    line-height: 0;
    margin: 0 2px;
    width: 16px;
    height: 16px;
    border: 0 none;
    cursor: pointer;
    outline: none;
    background: url(./images/zTreeStandard.png) no-repeat  -144px 0;
  }

  .v-tree-container .v-tree-edit{
    background-position: -110px -48px;
  }
  .v-tree-container .v-tree-remove{
    background-position: -110px -64px;
  }
</style>

<template>
  <div>
    <tree-node :nodes="nodes" :currentChange="currentChange">
      <template slot-scope="{node}">
        <slot :node="node"></slot>
      </template>
    </tree-node>
  </div>
</template>

<script>
import treeNode from './tree-node'

export default {
  name: 'tree',
  props: {
    nodes: Array,
    currentChange: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },
  components: {
    treeNode
  },
  methods: {
    HandleClick (node) {
      console.log(node.id)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <el-dialog title="设置权限" :visible.sync="setVisible" width="330px" :before-close="closeEvent">
      <div style="height:1px;background:#dcd8d8"></div>
      <div style="height:300px;overflow-y:auto">
        <el-tree  v-loading="treeLoading"
          :data="permissionList"
          show-checkbox
          node-key="id"
          ref="tree"
          check-strictly
          highlight-current
          @check="checkEvent"
          >
        </el-tree>
      </div>
    </el-dialog>
</template>

<script>
import {dataFormat} from '../../../utils/main'
export default {
    data(){
        return {
            checkedAll:false,
            treeLoading:false,
            list: [],
          permissionList: [],
            permissionMap: {},
            options: {
            data: {
                simpleData: {
                    idKey: "id",
                    pIdKey: "parentId",
                },
                key: {
                    name: "permissionName",
                    children: 'children'
                }
            }
        },}
    },
  props: {
    roleId:String,
    versionId:String,
    setVisible: Boolean
  },
    async created() {
        await this.getPermission();
        this.setCheckedKeys();
    },
  methods: {
      async checkedAllEvent(val){
          let arr = [];
          if(val) {
              arr = this.list.map(item=>item.id);
          }
          this.$refs.tree.setCheckedKeys(arr);
          let res = await this.$post('/admin/updateOpRolePermission',{
              roleId:this.roleId,
              versionId:this.versionId,
              permissionIds:JSON.stringify(arr)
          })
      },
      async checkEvent(data,checked){
          let arr = this.$refs.tree.getCheckedKeys()
          let parentId = data.parentId
          if(arr.indexOf(data.id)>=0){
              let parentIds = this.dropParentId(parentId);
              arr = [...arr,...parentIds]
              let _arr = new Set(arr)
              arr = Array.from(_arr)
          }
          this.$refs.tree.setCheckedKeys(arr);
          let res = await this.$post('/admin/updateOpRolePermission',{
              roleId:this.roleId,
              versionId:this.versionId,
              permissionIds:JSON.stringify(arr)
          })
          this.checkedAll = this.list.length === arr.length
      },
      dropParentId(parentId,arr=[]){
          if(parentId){
             arr.push(parentId)
              if(this.permissionMap[parentId].parentId){
                 this.dropParentId(this.permissionMap[parentId].parentId,arr)
              }
          }else{
              arr.push(parentId)
          }
          return arr
      },
    closeEvent () {
      this.$emit('closeSet');
    },
      async getPermission() {
        this.treeLoading=true;
          let res = await this.$get('/admin/getOpPermissionList')
          this.list = JSON.parse(JSON.stringify(res.permissionList));
          let map = {};
          this.list.forEach(item=>{
            map[item.id] = item
          });
          let data = dataFormat(this.options,res.permissionList)
          this.permissionList = data.list;
          this.permissionMap = data.map;
          this.treeLoading=false;
      },

      async setCheckedKeys() {
        let res = await this.$get('/admin/getOpRolePermissionList',{roleId:this.roleId})
          let arr = new Array();
          for(var i = 0;i < res.rolePermissionList.length;i++) {
              arr[i] = res.rolePermissionList[i].permissionId;
          }
          this.$refs.tree.setCheckedKeys(arr);
          this.checkedAll = this.list.length === arr.length
      }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <div class="e-breadcrumb">权限配置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class=" user-container">
            <div class="tree-container">
              <v-tree
                  v-loading="treeLoading"
                  :nodes="nodes"
                  :current-change="currentRow"
              >
              </v-tree>
            </div>
            <div class="order-table-wrapper" v-if="versionId">
              <div class="e-card">
                <div class="e-card-body" style="padding-top:35px">
                  <div class="e-handle-box" style="margin-bottom: 50px">
                    <div class="handle-box">
                      <span class="v-form-label">级别名称:</span>
                      <el-input placeholder="请输入级别名称" size="small" style="width:265px" v-model="roleName"
                                clearable
                                class="handle-input mr10"></el-input>
                      <div style="margin-left: 180px">
                        <el-row type="flex" justify="end">
                          <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                          </el-button>
                        </el-row>
                      </div>
                    </div>
                  </div>
                  <div class="e-table-wrapper" style="margin-top: -15px">
                    <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
                      <thead>
                      <tr>
                        <th width="150" class="th-l">级别名称</th>
                        <th width="130" class="th-l">创建时间</th>
                        <th width="400" class="th-l">操作</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                        <td class="td-l"><span>{{row.roleName}}</span></td>
                        <td class="td-l"><span>{{row.createTime}}</span></td>
                        <td class="td-l">
                          <el-button type="text" icon="el-icon-edit" @click="showRole(index)">设置权限</el-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="pagination">
                      <el-pagination
                          background
                          @current-change="handleCurrentChange"
                          :pager-count="7"
                          layout="total, prev, pager, next,jumper"
                          :total="pagination.count"
                          :page-count="pagination.pages"
                          :current-page="pagination.pageNumber"
                      ></el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddRole
      v-if="setVisible"
      :setVisible="setVisible"
      @closeSet="closeSetView"
      :roleId="roleId"
      :versionId="versionId"
    />
  </div>
</template>

<script>
import AddRole from './set-permissions'
import vTree from '@/components/common/v-tree/tree'
import {dataFormat} from '../../../utils/main.js'

export default {
    data() {
        return {
            treeLoading: false,
            tableLoading: false,
            nodes: [],
            roleName: "",
            roles:[],
            list: [],
            pagination: {
                count: 0,
                pageNumber: 0,
                pageSize: 0,
                pages: 1
            },
            setVisible: false,
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "versionName",
                        children: 'children'
                    }
                }
            },
            versionId: '',
            roleId:''
        };
    },
    async created() {
        this.versionList()
    },
    methods: {
        currentRow(item){
            this.userName=''
            this.versionId = item.id
            this.searchEvent()
        },
        handleCurrentChange(page) {
            this.requestSearch(page)
        },
        searchEvent() {
            this.requestSearch()
        },

        async requestSearch(page) {
            this.tableLoading = true;
          let currentPage = page || 1;
          let data = {
            roleName: this.roleName,
            size: this.size == '' ? '10' : this.size,
            currentPage
          }
          let dataSet = await this.$get("/admin/getOpRoleForPage", data)
            let res = dataSet.dataSet
            this.list = res.list;
            this.pagination = {
                count: res.count,
                pageNumber: res.pageNumber,
                pageSize: res.pageSize,
                pages: res.pages
            }
            this.tableLoading = false;
        },


        showRole (index) {
            let item = this.list[index]
            this.roleId = item.id
            this.setVisible = true
        },

        //获取版本信息
        async versionList() {
            this.treeLoading = true;
            let res = await this.$get("/admin/getVersionList");
            let data=  dataFormat(this.options,res.versionList)
            this.nodes = data.list
            this.treeLoading = false
        },
      closeSetView() {
        this.setVisible = false;
      },
    },
    components: {
        vTree,
        AddRole
    }
};
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  .user-container{
    display: flex;
  }
  .tree-container {
    margin-right: 10px;
    padding:10px;
    flex:0 0 280px;
    width: 280px;
    height: 400px;
    overflow-y:auto;
    border:1px #ebeef5 solid;
  }
  .table-container{
    flex:1;
    display: inline-block;
  }
  .e-card-body {
    padding: 25px !important;
}
  .off {
    background-color: #fff;
  }
  .on {
    background-color: #ececea;
  }
</style>

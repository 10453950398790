import { render, staticRenderFns } from "./tree.vue?vue&type=template&id=18bfa064&scoped=true&"
import script from "./tree.vue?vue&type=script&lang=js&"
export * from "./tree.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18bfa064",
  null
  
)

export default component.exports